export class Goals {
  public goal: string;
  public otherDecisionMakers: string[];
  public rememberedFor: string;
  constructor() {
    this.goal = "";
    this.otherDecisionMakers = [];
    this.rememberedFor = "";
  }
}

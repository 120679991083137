export interface IParent {
  Age: number;
  deceased: boolean;
  occupation: string;
  health: string;
  livingSituation: string;
}
export class Parent {
  Age: number;
  deceased: boolean;
  occupation: string;
  health: string;
  livingSituation: string;
  constructor() {}
}

import { Component, OnInit } from "@angular/core";
import {NavigateService} from "../../navigate.service";
import {AuthService} from "../../auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {

  constructor(public navigate:  NavigateService, public authService: AuthService) { }

  ngOnInit() {
  }
}
